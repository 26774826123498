@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "battlestar";
  src: url("../public/fonts/battlestar/BATTLEST.TTF");
  font-weight:normal;
  font-style:normal;
}
@font-face {
  font-family: "monoton";
  src: url("../public/fonts/monoton/Monoton-Regular.ttf");
  font-weight:normal;
  font-style:normal;
}
@font-face {
  font-family: "retro_stereo_wide";
  src: url("../public/fonts/retro_stereo_wide/Retro_Mono_Wide.ttf");
  font-weight:normal;
  font-style:normal;
}
@font-face {
  font-family: "retro_stereo_wide";
  src: url("../public/fonts/retro_stereo_wide/Retro_Stereo_Wide.ttf");
  font-weight:bold;
  font-style:normal;
}
@font-face {
  font-family: "techno_board";
  src: url("../public/fonts/techno_board/TechnoBoard.ttf");
  font-weight:bold;
  font-style:normal;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,h2,h3,h4,h5,h6 { 
  font-family: 'monoton';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header.active{
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  @apply bg-[#0D0620];
  @apply xl:px-4;
  @apply xl:py-4;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.hero-section-logo{
  position: absolute;
  top: 22%;
  right: 17.5%;
  /* animation-name: spin-slow;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;  */
}

.mega-graph .head-bg {
  height: 40px;
  background: -webkit-gradient(linear, left top, right top, from(#36082f),  to(#7a092a));
  background: -webkit-linear-gradient(to right, #36082f 0%, #7a092a 100%);
  background: -o-linear-gradient(to right, #36082f 0%, #7a092a 100%);
  background: linear-gradient(to right, #36082f 0%, #7a092a 100%);
  position: absolute;
  top: 0;
  opacity: 0.6;
  left: 0;
  width: 100%;
}
.mega-graph .item {
  /* float: left; */
  height: 450px;
  /* width: 10%; */
  position: relative;
}
.mega-graph .item:before {
  content: "";
  display: block;
  clear: both;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #47246E;
  z-index: 5;
  opacity: 0.4;
}
.mega-graph .item .graph-head {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 8;
  height: 40px;
  line-height: 40px;
}
.mega-graph .item .graph-head .num {
  @apply text-white;
  @apply text-sm;
}
.mega-graph .item .bar-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  top: 40px;
}
.mega-graph .item .bar-inner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #260729;
  background: -webkit-gradient(linear, left top, right top, from(#260729), to(#4e0726));
  background: -webkit-linear-gradient(left, #260729 0%, #4e0726 100%);
  background: -o-linear-gradient(left, #260729 0%, #4e0726 100%);
  background: linear-gradient(to right, #260729 0%, #4e0726 100%);
}
.mega-graph .item .bar-inner:before {
  content: "";
  display: block;
  clear: both;
  top: -4px;
  right: -7px;
  width: 7px;
  height: 100%;
  background: #1F0D44;
  background: -webkit-gradient(linear, left top, right top, from(#4e0726), to(#260729));
  background: -webkit-linear-gradient(left, #4e0726 0%, #260729 100%);
  background: -o-linear-gradient(left, #4e0726 0%, #260729 100%);
  background: linear-gradient(to right, #4e0726 0%, #260729 100%);
  -webkit-transform: skew(0deg, -45deg);
  -ms-transform: skew(0deg, -45deg);
  transform: skew(0deg, -45deg);
  position: absolute;
}
.mega-graph .item .bonus-info {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  @apply text-center;
  @apply md:text-sm;
  @apply text-xs;
  @apply text-white;
}
.mega-graph .item .bar-inner:after {
  content: "";
  display: block;
  clear: both;
  position: absolute;
  top: -7px;
  right: -3px;
  left: 3px;
  height: 7px;
  background: #ccc;
  -webkit-transform: skew(-45deg);
  -ms-transform: skew(-45deg);
  transform: skew(-45deg);
  background: -webkit-gradient(linear, left top, right top, from(#2d1030), to(#4e0726));
  background: -webkit-linear-gradient(left, #2d1030 0%, #4e0726 100%);
  background: -o-linear-gradient(left, #2d1030 0%, #4e0726 100%);
  background: linear-gradient(to right, #2d1030 0%, #4e0726 100%);
}
.about-image-box img.about-wheel{
  animation-name: spin-slow;
  animation-duration: 15000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.square-1 {
  position: absolute;
}

.square-2 {
 position: absolute;
}

/* Media */
@media(max-width:767px){
  .hero-section-logo{
    position: relative;
    top: 0;
    right: auto;
    left: 0;
  }
  .mega-graph .item .bonus-info{
    font-size: 10px;
  }
}

/* Animation CSS */
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
} 

@keyframes spin-slow {
  from{transform:rotate(0)}
  to{transform:rotate(360deg)}
} 
